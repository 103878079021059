import React, { useState, useEffect } from "react";
import Layout from "components/layout";
import SEO from "components/seo";

export default function Detail(props) {
  const [style, setStyle] = useState({ visibility: "hidden" });
  const {
    pageContext: {
      data: { data },
    },
  } = props;
  return (
    <Layout>
      <SEO
        title={data.meta_title || data.title}
        description={data.meta_description || data.title}
        slug={data.slug}
      />
      <div className="flex items-center justify-center flex-col">
        {/* upper component start */}
        <div className="bg-banner-image h-52 bg-cover bg-center bg-no-repeat w-full">
          {/* <img src={HeadImage} alt="" className="h-full" /> */}
        </div>
        {/* upper component ends */}
        {/* bottom component starts */}
        <div className="flex items-center justify-center bg-darkestgray w-full">
          <div className="flex justify-evenly max-w-screen-2xl w-full xl:w-9/12 flex-col lg:flex-row">
            <div className="lg:w-9/12 w-full px-6 sm:px-0">
              <h1 className="text-lightBlue text-2xl font-bold pt-14 pb-8">
                {/* The 7th Asian Translation Traditions Conference */}
                {data?.title}
              </h1>
              <div className="border-t border-white  text-left">
                <div className="mt-4">
                  <h5 className="inline font-primary text-xl text-blackNew-300">
                    <i>
                      {/* Kuala Lumpur, Malaysia , Malaysia. | 26.09.2016 -
                      30.09.2016 */}
                      {`${data?.place}, ${data?.country}, | ${data?.startdate} - ${data?.enddate}`}
                    </i>
                  </h5>
                  <div
                    className="right inline-flex items-center sm:pl-3 pr-6"
                    onMouseEnter={(e) => {
                      setStyle({ visibility: "unset" });
                    }}
                    onMouseLeave={(e) => {
                      setStyle({ visibility: "hidden" });
                    }}
                    role="button"
                    tabIndex={0}
                  ></div>
                </div>
                <div className="">
                  <img
                    src={data?.cover_pic}
                    alt=""
                    className="inline-block height-min-content object-cover float-none md:float-left mb-3 md:m-8"
                  />

                  <span
                    className="md:pl-8 pl-0 text-new font-primary text-white font-thin md:mt-0 mt-4 break-words p-6 anchor-color-fix"
                    dangerouslySetInnerHTML={{
                      __html: data?.description,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="md:w-1/4 w-full md:mt-16 mt-11 sm:border-l sm:border-white sm:pl-6 h-2/3">
              <p className="text-lightBlue text-3xl  pb-8 font-primary text-center sm:text-left">
                Why choose us
              </p>
              <div className="sm:w-9/12 text-center mb-7">
                <img
                  src="https://skins.tomedes.com/frontend/images/why-us-img/24-7-human-support.svg"
                  alt=""
                  className="m-auto inline-block w-[90px] h-[100px]"
                />
                <p className="text-lg font-primary text-white mt-4">
                  24/7 Human Support
                </p>
              </div>
              <div className="sm:w-9/12 text-center mb-7">
                <img
                  src="https://skins.tomedes.com/frontend/images/why-us-img/quality-guaranteed-color.svg"
                  alt=""
                  className="m-auto inline-block w-[90px] h-[100px]"
                />
                <p className="text-lg font-primary text-white mt-4">
                  1 Year Guarantee
                </p>
              </div>
              <div className="sm:w-9/12 text-center mb-7">
                <img
                  src="https://skins.tomedes.com/frontend/images/why-us-img/50000-business.svg"
                  alt=""
                  className="m-auto inline-block w-[90px] h-[100px]"
                />
                <p className="text-lg font-primary text-white mt-4">
                  95,000 Business Customers
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
